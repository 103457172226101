import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { motion } from 'framer-motion';
import linkedin from "./img/linkedin.svg";
import github from "./img/github.svg";
import './App.css';

enum DisplayEnum {
  NONE,
  ACTIVE,
  CABIN
}

function App() {
  const [display, setDisplay] = React.useState<DisplayEnum>(DisplayEnum.NONE);
  const isSmallDevice = useMediaQuery('(max-width: 640px)');
  return (
      <Box className="App" style={{height: '100vh'}}>
        <Box style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
        <motion.div
        animate={{ scale: 1 }}
        initial={{ scale: 0.9 }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
        >
        <Box style={{display: !isSmallDevice ? 'inline' : 'flex', flexDirection: 'column', justifyContent: 'center'}}>
          <Typography style={{color: 'white', fontSize: '5em', lineHeight: '1em', fontFamily: 'Varela', fontWeight: 800, display: 'inline'}}> D r e w </Typography>
          <Typography style={{color: 'white', fontSize: '5em', lineHeight: '1em', fontFamily: 'Varela', fontWeight: 800, display: 'inline'}}> H a r t</Typography>
        </Box>
        <Box style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'space-around', paddingTop: '20px', gap: 5, maxWidth: '625px', width: '100%', height: '100%', flexGrow: 1}}>
          <Typography style={{color: 'white', fontFamily: 'Varela',}}>Software Engineer </Typography>
          <Box style={{display: 'flex', justifyContent: 'center', gap: 10 }}>
            <Typography style={{color: 'white', fontFamily: 'Varela'}}>Personal Projects:</Typography>
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 1.1 }}
            >
              <Typography style={{color: 'white', cursor: 'pointer', fontFamily: 'Varela', backgroundColor: 'rgba(255,255,255,0.2)'}} onMouseOver={() => setDisplay(DisplayEnum.ACTIVE)}>Active</Typography>
            </motion.div>
            <Typography style={{color: 'white', fontFamily: 'Varela',}}>|</Typography>
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 1.1 }}
            >
              <Typography style={{color: 'white', cursor: 'pointer', fontFamily: 'Varela', backgroundColor: 'rgba(255,255,255,0.2)'}} onMouseOver={() => setDisplay(DisplayEnum.CABIN)} >Cabin</Typography>
            </motion.div>
          </Box>
          <Box style={{height: '200px', overflow: 'auto'}}>
            { display === DisplayEnum.ACTIVE &&
               <motion.div
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
               >
              <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '50px', paddingRight: '50px', gap: 10}}>
                <Typography style={{color: 'white', fontFamily: 'Varela', fontStyle: 'italic', textAlign: 'left'}}>GPT-powered active listening app that intakes conversational audio and parses it for miscommunication</Typography>
                <Box>
                  <Typography style={{color: 'white', fontFamily: 'Varela', fontStyle: 'italic', textAlign: 'left'}}>Tech: </Typography>
                  <Typography style={{color: 'white', fontFamily: 'Varela', fontStyle: 'italic', textAlign: 'left'}}>
                    <li> React + Typescript </li>
                    <li> AWS (S3, Amplify, AppSynch, Lambda) </li>
                    <li> OpenAI whisper-1 and gpt-4o</li>
                  </Typography>
                </Box>
                <Box style= {{display: 'flex', gap: 10}}>
                  <motion.div
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 1.1 }}
                  >
                    <a style={{textDecoration: "none"}} href="https://d2wvuehlect9yz.cloudfront.net/">
                      <Typography style={{fontFamily: 'Varela', color:'rgba(0,0,0,0.25)', backgroundColor: 'rgba(255,255,255,0.4)'}}>Launch App</Typography>
                    </a>
                  </motion.div>
                  <motion.div
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 1.1 }}
                  >                  
                    <a style={{textDecoration: "none"}} href="https://github.com/drewjhart/active">
                      <Typography style={{fontFamily: 'Varela',  color:'rgba(0,0,0,0.25)', backgroundColor: 'rgba(255,255,255,0.4)'}}>Code on Github</Typography>
                    </a>
                  </motion.div>
                </Box>
              </Box>
              </motion.div>
            }
            { display === DisplayEnum.CABIN &&
              <motion.div
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
              >
                <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '50px', paddingRight: '50px', gap: 10}}>
             <Typography style={{color: 'white', fontFamily: 'Varela', fontStyle: 'italic', textAlign: 'left'}}>A cabin designed for a friend and rendered in-browser via three.js</Typography>
              <Box>
                  <Typography style={{color: 'white', fontFamily: 'Varela', fontStyle: 'italic', textAlign: 'left'}}>Tech: </Typography>
                  <Typography style={{color: 'white', fontFamily: 'Varela', fontStyle: 'italic', textAlign: 'left'}}>
                    <li> Javascript </li>
                    <li> three.js </li>
                  </Typography>
                </Box>
                <Box style= {{display: 'flex', gap: 10}}>
                  <motion.div
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 1.1 }}
                  >
                    <a style={{textDecoration: "none"}} href="https://www.drewjhart.com/cabin">
                      <Typography style={{fontFamily: 'Varela', color:'rgba(0,0,0,0.25)', backgroundColor: 'rgba(255,255,255,0.4)'}}>Launch App</Typography>
                    </a>
                  </motion.div>
                  <motion.div
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 1.1 }}
                  >                  
                    <a style={{textDecoration: "none"}} href="https://github.com/drewjhart/cabin">
                      <Typography style={{fontFamily: 'Varela',  color:'rgba(0,0,0,0.25)', backgroundColor: 'rgba(255,255,255,0.4)'}}>Code on Github</Typography>
                    </a>
                  </motion.div>
                </Box>
                </Box>
             </motion.div>
            }
          </Box>
          </Box>
          </motion.div>
          <Box style={{position: 'absolute', bottom: '20px', left: '50%', transform: 'translate(-50%,0%)',display: 'flex', justifyContent: 'center', gap: 10, paddingTop: '10px' }}>
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 1.1 }}
            >
              <a href="https://www.linkedin.com/in/drewjhart/"> <img src={linkedin} style={{height: '25px', width: '25px'}}/></a>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 1.1 }}
            >
              <a href="https://github.com/drewjhart/"> <img src={github} style={{height: '25px', width: '25px'}}/></a>
            </motion.div>
          </Box>
        </Box>    
      </Box>
    
  );
}

export default App;